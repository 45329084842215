import { html, css, formCss } from 'Elements';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import { VALIDATION_STATUS_ICON, VALIDATION_FIELDS } from './config.js';
import validationCss from './validation.css.js';
import { Fetcher } from 'Utils';

class ContactValidation extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      formCss,
      validationCss,
      css`
        .marginbottom {
          margin-bottom:10px;
        }

        .bold {
          font-weight: bold;
        }

        sl-tab {
          position:relative;
        }

        sl-tab m-icon {
          position: absolute;
          right: -4px;
          top: 5px;
        }

        table {
          font-size:0.9em;
        }

        table th {
          text-align:left;
        }

        table m-icon {
          font-size:1.8em;
        }

        table td {
          vertical-align: top;
        }
      `
    ]
  }

  static get properties() {
    return {
      item: { type: Object }
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            close:'Close',
            tabs:{
              status:'Status',
              history:'History',
              action:'Actions'
            },
            status:{
              waiting:'Pending',
              validated:'Validated',
              not_validated:'Not validated',
              none:'N/A',
              error:'Error: ',
              undefined:'N/A'
            },
            reset:'Reset',
            trigger:'Trigger',
          },
        },
        french:{
          translation: {
            close:'Fermer',
            tabs:{
              status:'Statut',
              history:'Historique',
              action:'Actions'
            },
            status:{
              waiting:'En attente',
              validated:'Validé',
              not_validated:'Non validé',
              none:'N/A',
              error:'Erreur: ',
              undefined:'N/A'
            },
            reset:'Réinitialiser',
            trigger:'Déclencher',
            reset_email_html:'Réinitialiser l\'état de la validation des adresses email de cet utilisateur ?',
            reset_mobile_html:'Réinitialiser l\'état de la validation des mobiles de cet utilisateur ?',           
            trigger_email_html:'Lancer la validation des adresses email de cet utilisateur ?',
            trigger_mobile_html:'Lancer la validation des mobiles de cet utilisateur ?',

            reset_email_label:'Confirmation',
            reset_mobile_label:'Confirmation',
            trigger_email_label:'Confirmation',
            trigger_mobile_label:'Confirmation',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.item = null;
    this.viewOnly = true;
    this.urlVar = 'idu';
  }

  getParentName(item) {
    return item.parentId ? this.parent.items.find(i => i._id === item.parentId).name : '';
  }

  getItemTitle(opts = {}) {
    return this.item.firstname + ' ' + this.item.lastname;
  }

  renderStatusIcon(device, level) {
    /*
    const statusField = `validation_${device}_${level}_status`;
    const icon = VALIDATION_STATUS_ICON[device][this.item[statusField]];
    return html`<m-icon name="${icon}" color="green"></m-icon>`;
    */

    const field = `validation_${device}_${level}_status`;
    const statusIcon = VALIDATION_STATUS_ICON[device];
    const value = this.item[`${device}_${level}`];

    let icon = statusIcon.undefined;
    let css = 'undefined';

    if (value) {
      icon = statusIcon[this.item[field] || 'not_validated'];
      css = 'not_validated';
    }

    css = 'validation ' + (this.item[field] || css);
    return html`<m-icon name="${icon}" class="${css}"></m-icon>`;
  }

  renderTableTd(device, level) {
    const value = this.item[`${device}_${level}`];
    const statusField = `validation_${device}_${level}_status`;
    const status = this.item[statusField] || 'not_validated';
    let statusComment = '';

    if (status === 'error') {
      statusComment=html`${this.item[`validation_${device}_${level}_error`]}`;
    }

    return html`
      <tr>
        <td align="center">${this.renderStatusIcon(device, level)}</td>
        <td>${value || 'N/A'}</td>
        ${value 
          ? html`<td>${this._tl(`status.${status}`)} ${statusComment}</td>`
          : html`<td></td>`
        }
      </tr>
    `;
  }

  renderTableHeader() {
    return html`
      <thead>
        <tr>
          <th width="30"></th>
          <th width="250"></th>
          <th></th>
        </tr>
      </thead>
    `;
  }

  showModal(device, action) {
    this.device = device;
    this.action = action;
    this.modalAction = this.modalAction || this.qs('#modal-dialog-validation');

    this.modalAction.qs('p').innerHTML = this._tl(`${action}_${device}_html`);
    this.modalAction.qs('sl-button[slot="bt2"]').textContent = this._tl(action);
    this.modalAction.label = this._tl(`${action}_${device}_label`);
    this.modalAction.show();
  }

  async run() {
    this.modalAction.loadingButtons();
    const url = `private/admin/registry/tree/${this.item._id}/validation/${this.action}`;
    const response = await Fetcher.put(url, { device: this.device });
    this.modalAction.resetButtons();
    this.modalAction.hide();
    if (!response || !response.ok) {
      return;
    }

    this.item = response.data;
    window.dispatchEvent(new CustomEvent('registry-updated', { detail: response.data }));
  }

  render() {
    if (!this.item) return '';
    return html`
      <modal-drawer>
        <style type="text/css">${this.constructor.styles}</style>
        <sl-tab-group>
          <sl-tab slot="nav" panel="status" class="bold">${this._tl('tabs.status')}</sl-tab>
          <sl-tab slot="nav" panel="history" class="bold">${this._tl('tabs.history')}</sl-tab>

          <sl-tab-panel name="status">
            <section-header size="small" bold>
              Adresses email
              <m-icon title="Réinitialiser" variant="button" name="stop_circle" slot="right" @click="${ev => this.showModal('email','reset')}"></m-icon>
              <m-icon title="Rejouer" variant="button" name="play_circle" slot="right" @click="${ev => this.showModal('email','trigger')}"></m-icon>
            </section-header>
            <table>
              ${this.renderTableHeader()}
              <tbody>
                ${this.renderTableTd('email','primary')}
                ${this.renderTableTd('email','secondary')}
              </tbody>
            </table>
            <br/>
            <section-header size="small" bold>
              Mobiles
              <m-icon title="Réinitialiser" variant="button" name="stop_circle" slot="right" @click="${ev => this.showModal('mobile','reset')}"></m-icon>
              <m-icon title="Rejouer" variant="button" name="play_circle" slot="right" @click="${ev => this.showModal('mobile','trigger')}"></m-icon>
            </section-header>
            <table>
              ${this.renderTableHeader()}
              <tbody>
                ${this.renderTableTd('mobile','primary')}
                ${this.renderTableTd('mobile','secondary')}
              </tbody>
            </table>
            <br/><br/>
          </sl-tab-panel>
          <sl-tab-panel name="history">
            <box-styled variant="warning">Pas encore implémenté</box-styled>
          </sl-tab-panel>
        </sl-tab-group>

        <sl-button slot="bt2" variant="text" close="true">${this._tl('close')}</sl-button>
      </modal-drawer>

      <modal-dialog id="modal-dialog-validation">
        <p id="modal-dialog-validation-text">
        </p>
        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" @click=${this.run}></sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('registry-node-contact-validation', ContactValidation);

