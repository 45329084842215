import { css } from 'lit-element';

export default css`

  .validation.waiting {
    color:var(--sl-color-orange-500);
  }

  .validation.validated {
    color:var(--sl-color-primary-700);
  }

  .validation.not_validated {
    color:var(--sl-color-danger-700);
  }

  .validation.none {
    color:var(--sl-color-neutral-500);
  }

  .validation.error {
    color:var(--sl-color-danger-700);
  }

  .validation.red {
    color:var(--sl-color-danger-500);
  }

  .validation.green {
    color:var(--sl-color-primary-700);
  }

  .validation.orange {
    color:var(--sl-color-orange-500);
  }

  .validation.disabled {
    color:var(--sl-color-neutral-400);
    text-decoration:line-through;
  }
`;